import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import TitleArea from '../components/TitleArea'
import { Container, Columns, Column, Content, Title, Section } from 'bloomer'

export default ({ data }) => (
  <Layout>
    <SEO title="Dog Walking" alias="dog-walking" />
    <TitleArea title="Dog Walking" />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Content>
              <p>
                We offer dog walking services for dogs of all sizes and energy
                levels.
              </p>

              <p>
                While exercise in our large outdoor runs is often sufficient. A
                walk allows us to spend more personal time with your pet and
                allows more active dogs some extra time outdoors.
              </p>

              <p>
                Walks are generally 10 to 15 minutes in length, and usually
                involve a trip down some of our lovely trails and country lanes.
              </p>
            </Content>
            <Title
              tag="h2"
              // hasTextAlign="centered"
            >
              Rates
            </Title>
            <Content>
              <p>
                Walks are usually scheduled during a dog’s time boarding with
                us, and cost only $3.50 per walk.
              </p>
            </Content>
          </Column>

          <Column isSize="narrow">
            <Img fixed={data.image1.childImageSharp.fixed} />
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "dog-walking/image1.jpg" }) {
      childImageSharp {
        fixed(width: 410, height: 540) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
